import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer
} from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
        <div>
        <h1 id="brandguidelines">Brand Guidelines</h1>
        <p><em>Effective Date: November 24, 2019</em></p>
        <p>The AirPage brand includes the words, phrases, symbols, designs and other distinctive brand features associated with AirPage and our services (“Brand Assets”). Examples of our Brand Assets include the word “AirPage” and our logos. Our Brand Assets are trade names, trademarks, service marks and trade dress of AirPage.</p>
        <p>AirPage encourages and supports other products and services that use and supplement our services. At the same time, we must protect our reputation and brand identity. So we ask that you follow these Brand Guidelines, which are intended to promote consistent use of our Brand Assets. This makes it easier for people to instantly recognize references to AirPage, and prevents consumer confusion. These guidelines also help protect AirPage’s intellectual property.</p>
        <p>If you don’t agree to these Brand Guidelines, you don’t have a right to, and shouldn’t, use the Brand Assets.</p>
        <h3 id="generalbrandguidelines"><strong>General Brand Guidelines</strong></h3>
        <p><strong>Do:</strong></p>
        <ul>
            <li><p>Use our Brand Assets to refer to AirPage, our services or anything else we offer.</p></li>
            <li><p>Write “AirPage” as a single word, and only capitalize “A” and “P”.</p></li>
            <li><p>Comply with our <a href="/logo-guidelines">Logo Guidelines</a>,&nbsp;<a href="/corporate-colors">Corporate Colors</a>,&nbsp;<a href="/terms-of-service/">Terms of Service</a>&nbsp;and <a href="/acceptable-use-policy">Acceptable Use Policy</a>.</p></li>
        </ul>
        <p><strong>Don't:</strong></p>
        <ul>
            <li><p>Use the Brand Assets in a way that suggests or implies partnership, sponsorship or endorsement by AirPage.</p></li>
            <li><p>Modify or alter the Brand Assets.</p></li>
            <li><p>Incorporate the Brand Assets, or anything confusingly similar, into your trademarks, domain names, logos or similar content.</p></li>
            <li><p>Present the Brand Assets in a way that makes them the most prominent or distinctive feature of what you’re creating.</p></li>
            <li><p>Use the Brand Assets in merchandise or other products such as clothing, hats or mugs.</p></li>
            <li><p>Assert rights over the AirPage brand or Brand Assets, whether by domain name registration, trademark registration or anything else.</p></li>
            <li><p>Use trademarks, domain names, logos or other content that imitate or could be confused with AirPage.</p></li>
            <li><p>Feature AirPage on materials associated with sexually explicit content, unlawful activity or other materials that violate our <a href="/terms-of-service/">Terms of Service</a> and <a href="/acceptable-use-policy">Acceptable Use Policy</a>.</p></li>
        </ul>
        <p>To help us address future branding issues, we may modify these Brand Guidelines, without notice to you. We’ll post the most recent version on our website. You’re responsible for following any modified terms, so be sure to review these Brand Guidelines regularly.</p>
        </div>
    </LegalContainer>
  </MainLayout>
);
